
import {Vue, Component, Prop} from 'vue-property-decorator'
import BasePanel from '@/components/base/BasePanel.vue'
import BaseResourceAssociationDataTable from "@/components/base/BaseResourceAssociationDataTable.vue"
import { ApiResource, PropertyResource } from '@/resources'

@Component({components: {BasePanel, BaseResourceAssociationDataTable}})
export default class PropertiesRecentlyUpdated extends Vue {

  @Prop({required: false, default: true}) dense : boolean = true
  @Prop({default: false}) hidePagination !: boolean

  headers = [
      { text: 'Address', value: 'name', width: '100%' },
      { text: 'Last updated', value: 'lastUpdated', width: 135, cellClass: 'lastUpdated' },
  ]

  get propertiesAssociation() {
    return new ApiResource().properties
  }
  
  propertyAddress(p : any) {
    return PropertyResource.getPropertyAddressNoCity(p)
  }

  gotoProperty(p : any) {

    this.$router.push({ name: 'property', params: {pid:p.id}})
  }


}
