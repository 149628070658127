
import { AppointmentResource, AssociationResource, ClientResource, PropertyResource } from "@/resources";
import { orderBy } from "lodash-es";
import { DateTime } from "luxon";
import { Component, Watch, Vue, Prop} from "vue-property-decorator"
import { RequiredProjections } from "../base/BaseMixins";
import {BaseAppointmentComponent} from "../base/BaseMixins"

@RequiredProjections("appointmentDetail")
@Component({components : {BaseAppointmentComponent}})
export default class AppointmentsItinerary extends Vue {
 
  @Prop({default : () => DateTime.now().toISODate()}) iDate !: string

  itineraryDate : DateTime = DateTime.fromISO(this.iDate)
  pickerMenu : boolean = false
  searchResource : AssociationResource<AppointmentResource[]> = new AppointmentResource().searchByStartDateBetween

  get searchVariables() {
    let startDate = this.itineraryDate.startOf('day')

    // ensure search is in GMT
    return {
      startDate: startDate.setZone('utc').toISO(),
      endDate : startDate.plus({days:1}).setZone('utc').toISO()
    }
  }

  @Watch("itineraryDate", {immediate: true})
  dateChanged(newVal : any, oldVal : any) {
    if (newVal != oldVal) {
      this.$emit('input', this.itineraryDate.toISODate())
    }
  }

  fireCount(ar : AssociationResource<any>) {
    this.$emit('count', ar.length())
  }

  filteredSortedData(rdata : any[]) {
    let sorted = orderBy(rdata, (a) => DateTime.fromISO(a.startDate), ['asc'])
    return sorted
  }

  formatTime(a : any) {
    return DateTime.fromISO(a.startDate).toFormat('t')
  }
  statusColor(a : any) {
    return AppointmentResource.getDefaultStatusBackgroundColor(a.status)
  }
  formatAddress(a : any) {
    return PropertyResource.getPropertyAddress(a.jobs[0].property)
  }
  hasClient(a : any) {
    return a.jobs[0].property.client
  }
  formatClient(a : any) {
    return this.hasClient(a) ? ClientResource.getClientName(a.jobs[0].property.client) : "No client file available"
  }
  jobRouterParams(j : any) {
    return {name: "job", params: {jid: j.id, pid:j.property.id, pmsId:j.propertyMaintenanceSystem.id}}
  }
  propertyRouterParams(appt : any) {
    return {name: "property", params: {pid:appt.jobs[0].property.id}}
  }

  setDateFromPicker(d : any) {
    this.itineraryDate = DateTime.fromISO(d)
    this.pickerMenu = false
  }
}
