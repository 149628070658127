
import { Component, Vue } from "vue-property-decorator";
import PropertyClientAddBothDialog from "./properties/PropertyClientAddBothDialog.vue"
import ClientAddDialog from "@/components/clients/ClientAddDialog.vue"
import AppointmentsItinerary from "./scheduling/AppointmentsItinerary.vue";
import AsyncComputed from "vue-async-computed-decorator";
import { ClientResource, MaintenanceJobResource, PropertyResource, UserResource } from "@/resources";
import { DateTime } from "luxon";
import { BaseEntityResourceComponent } from '@/components/base/BaseMixins'
import PropertySearchField from '@/components/properties/PropertySearchField.vue'
import PropertyHealthAlerts from "./properties/PropertyHealthAlerts.vue";
import ClientSearchField from '@/components/clients/ClientSearchField.vue'
import ClientsRecentlyUpdated from "./clients/ClientsRecentlyUpdated.vue";
import PropertiesRecentlyUpdated from "@/components/properties/PropertiesRecentlyUpdated.vue"
import JobsTableServerPaged from "@/components/jobs/JobsTableServerPaged.vue"


@Component({ components: { JobsTableServerPaged, PropertyHealthAlerts, ClientSearchField, PropertiesRecentlyUpdated, ClientsRecentlyUpdated, PropertySearchField, AppointmentsItinerary, BaseEntityResourceComponent, PropertyClientAddBothDialog, ClientAddDialog } })
export default class Dashboard extends Vue {
    propertyClientAddDialog : boolean = false
    cAddDialog : boolean = false
    iDate : string = DateTime.now().toISODate() 
    apptCount : number = 0
    jobsCount : number = 0
    apptUpdating : boolean = false
    jobsUpdating : boolean = false
    cRecentlyUpdated : boolean = false
    pRecentlyUpdated : boolean = false

    apptCardMinWidth : number = 450
    healthCardMinWidth : number = 242
    apptCardWidth : number = this.apptCardMinWidth
    healthCardWidth : number = this.healthCardMinWidth
    apptFocus : boolean = true
    propSearch : boolean = true

    doPropSearch() {
        this.propSearch = true
        this.focusSearch('focusPropertySearch')
    }
    doClientSearch() {
        this.propSearch = false
        this.focusSearch('focusClientSearch')
    }

    onResize() {
        //@ts-ignore
        this.apptCardWidth = document.getElementById('apptCard').clientWidth
         //@ts-ignore
        this.healthCardWidth = document.getElementById('healthCard').clientWidth
    }

    get today() {
        return DateTime.now().toFormat("EEEE, MMMM d, y")
    }

    get iDateString() {
        return DateTime.fromISO(this.iDate).toFormat("EEEE, MMMM d, y")
    }

    get clientStatistics() {
        return new ClientResource().statistics
    }
    get propertyStatistics() {
        return new PropertyResource().statistics
    }
    get jobs() {
        return new MaintenanceJobResource().all
    }

    focusSearch(type : any) {
        this.$root.$emit(type, true)
    }

    @AsyncComputed()
    async user() {
        const authUser = await this.$auth.getUser()
        const userResource = new UserResource(authUser.email)
        await userResource.get()
        return userResource.data()
    }
}
