
import {Component, Prop, Vue} from 'vue-property-decorator'
import BaseResourceAssociationDataTable from "@/components/base/BaseResourceAssociationDataTable.vue"
import { AssociationResource, ClientResource, MaintenanceJobResource, PropertyResource} from '@/resources'
import JobStatusChip from './JobStatusChip.vue'
const { DateTime } = require("luxon")

@Component({components: {JobStatusChip, BaseResourceAssociationDataTable}})
export default class JobsTableServerPaged extends Vue {

  @Prop({default: () => ({sort : 'startDate,asc', statuses : 'OPEN_UNSCHEDULED,OPEN_SCHEDULED'})}) associationParams !: any
  @Prop({required: true}) associationResource !: AssociationResource<MaintenanceJobResource[]>
  @Prop({required: false, default: true}) dense !: boolean
  @Prop({default: false}) hidePropertyAndClient !: boolean

  openJobHeaders = [
      { text: 'Status', value: 'status'},
      { text: 'Details', value: 'details', width: "100%" },
      { text: 'Start date', value: 'startDate', width: 100}
  ]

  get dateMed() {
    return DateTime.DATE_MED
  }

  clientName(client : any) {
    return ClientResource.getClientName(client)
  }
  propertyName(property : any) {
    return PropertyResource.getPropertyAddress(property)
  }

  gotoJob(job : any) {
    this.$router.push({ name: 'job', params: {jid:job.id, pid : job.property.id, pmsId: job.propertyMaintenanceSystem.id}})
  }

  get fetchParams() {
    return {...this.associationParams, projection: 'maintenanceJobSummary'}
  }

}
