
import {Component, Vue} from 'vue-property-decorator'
import BasePanel from '@/components/base/BasePanel.vue'
import { HealthReportResource } from '@/resources'
import { BaseEntityResourceComponent } from '../base/BaseMixins'
import HealthSummaryChips from '@/components/healthreports/HealthSummaryChips.vue'
import BaseOptionsMenu from '../base/BaseOptionsMenu.vue'

@Component({components: {HealthSummaryChips, BasePanel, BaseOptionsMenu, BaseEntityResourceComponent}})
export default class PropertyHealthAlerts extends Vue {

  get propertiesWithIssuesAssociation() {
    return new HealthReportResource().propertiesWithIssues
  }

}
