
import {Vue, Component, Prop} from 'vue-property-decorator'
import BasePanel from '@/components/base/BasePanel.vue'
import BaseResourceAssociationDataTable from "@/components/base/BaseResourceAssociationDataTable.vue"
import { ClientResource, ApiResource } from '@/resources'

@Component({components: {BasePanel, BaseResourceAssociationDataTable}})
export default class ClientsRecentlyUpdated extends Vue {

  @Prop({required: false, default: true}) dense : boolean = true
  @Prop({default: false}) hidePagination !: boolean

  headers = [
      { text: 'Client', value: 'name' },
      { text: 'Last updated', value: 'lastUpdated' },
  ]

  get clientsAssociation() {
    return new ApiResource().clients
  }
  
  clientName(client : any) {
    return ClientResource.getClientName(client)
  }

  gotoClient(client : any) {

    this.$router.push({ name: 'client', params: {cid:client.id}})
  }


}
